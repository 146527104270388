import * as React from 'react'
import type { HeadFC, PageProps } from 'gatsby'
import { chakra } from '@chakra-ui/react'
import { graphql, Link } from 'gatsby'
import { LayoutHeader } from '../components/layout/head'
import Layout from '../components/layout'

const IndexPage = ({
  data,
  location,
}: {
  data: Queries.BlogListQuery
  location: any
}) => {
  const {
    allMdx: { edges },
  } = data
  return (
    <Layout location={location}>
      <chakra.main>
        <chakra.section>
          <chakra.ul>
            {edges.map(({ node }) => {
              const {
                frontmatter: { slug, title, date },
                excerpt,
              } = node
              return (
                <chakra.li
                  display="block"
                  borderRadius="8px"
                  mb="20px"
                  border="1px solid rgba(0, 0, 0,.1)"
                  listStyleType="none"
                  key={node.id}
                >
                  <Link to={slug}>
                    <chakra.div
                      padding="10px 20px"
                      _hover={{ backgroundColor: 'rgba(0,0,0,.1)' }}
                    >
                      <chakra.p textAlign="right" color="gray" fontSize="12px">
                        {date}
                      </chakra.p>
                      <chakra.h2
                        fontSize="18px"
                        fontWeight="bold"
                        lineHeight="1.8"
                      >
                        {title}
                      </chakra.h2>
                      <chakra.p lineHeight="1.8" fontSize="14px">
                        {excerpt}
                      </chakra.p>
                    </chakra.div>
                  </Link>
                </chakra.li>
              )
            })}
          </chakra.ul>
        </chakra.section>
      </chakra.main>
    </Layout>
  )
}

export default IndexPage

export const Head: HeadFC = () => <title>ウェルネス</title>

export const pageQuery = graphql`
  query BlogList {
    allMdx(sort: { order: DESC, fields: frontmatter___date }, limit: 1000) {
      edges {
        node {
          id
          body
          excerpt
          frontmatter {
            date(formatString: "YYYY-MM-DD")
            slug
            title
          }
        }
      }
    }
  }
`
