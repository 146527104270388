import * as React from 'react'
import { Link } from 'gatsby'
import { LayoutHeader } from './layout/head'
import { chakra } from '@chakra-ui/react'
import { ReactNode } from 'react'
const Layout = ({
  location,
  title,
  children,
}: {
  location: Location
  title?: string
  children: ReactNode
}) => {
  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location.pathname === rootPath
  let header

  if (isRootPath) {
    header = <LayoutHeader />
  } else {
    header = <LayoutHeader />
  }

  return (
    <div className="global-wrapper" data-is-root-path={isRootPath}>
      <header className="global-header">{header}</header>
      <chakra.main margin="auto" w="600px" maxW="90vw" padding="20px">
        {children}
      </chakra.main>
      <chakra.footer>© {new Date().getFullYear()}, Wellness</chakra.footer>
    </div>
  )
}

export default Layout
