import { chakra } from '@chakra-ui/react'
import * as React from 'react'
import { Link } from 'gatsby';

export function LayoutHeader() {
  return (
    <chakra.head
      h="50px"
      boxShadow="0px 1px 10px 0px rgba(0, 0, 0, 0.25)"
      display="flex"
      alignItems="center"
      padding="0 20px"
    >
      <Link to="/">
        <chakra.h1 fontSize="20px" fontWeight="bold" color="#191919">
          ウェルネス
        </chakra.h1>
      </Link>
    </chakra.head>
  )
}
